.form-demo .card {
    min-width: 450px;
    
}
.form-demo .card form {
    margin-top: 1rem;
    
}
.form-demo .card .field {
    margin-bottom: 1.5rem;
       
}
@media screen and (max-width: 960px) {
    .form-demo .card {
        width: 80%;
    }
}
@media screen and (max-width: 640px) {
    .form-demo .card {
        width: 100%;
        min-width: 0;
    }
}
.column {
    float: left;
    width: 50%;
    padding: 10px;
     /* Should be removed. Only for demonstration */
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .form{
    display:flex;
    flex-direction: column;
    
  }
  .form-group{
    
    display:flex;
    flex-direction: column;
  }

  .form-group input{
    
    display:flex;
    flex-direction: column;
    margin-bottom:'8px';
   }
   .dialog{
    background-color: lightgrey !important;
   }
   
  .p-tabview-panels
  {
    max-height: 60vh;
    overflow-y: auto;
  }
  .p-tabview-title {  
    color: #8A00E5 !important; /* red */  
  }  
  