
/* DataTableDemo.css.. */

.datatable-crud-demo .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: flex-end;
}
.p-dialog-title{
    margin-left:20px;
    margin-top: 15px;
    margin-bottom: 10px;
    color:#8A00E5;
}

.p-dialog .p-dialog-header {
    border-bottom: 0 none !important;
    background: #ffffff !important;
    color: #343a40 !important;   
    padding:0px !important;
    border-top-right-radius: 6px !important;
    border-top-left-radius: 6px !important;
}

@media screen and (max-width: 960px) {
    .datatable-crud-demo .table-header {
        align-items: flex-start;
    }
}
.datatable-crud-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.datatable-crud-demo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}
.datatable-crud-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 960px) {
    .datatable-crud-demo .p-toolbar {
        flex-wrap: wrap;
    }
    .datatable-crud-demo .p-toolbar .p-button {
        margin-bottom: 0.25rem;
    }
    .datatable-crud-demo .table-header {
        flex-direction: column;
    }
    .datatable-crud-demo .table-header .p-input-icon-left, .datatable-crud-demo .table-header input {
        width: 100%;
    }
    
    }

                     