.form-container {
    
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    margin-top: 0em;
    margin-bottom: 0;
    padding: 0;
    background-color: #3E4052;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;  
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 
}

.background {
    position: absolute;
    background-color: #3E4052;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;    
    width:100%;
    height:100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.formBackground {
    height: 100%;
    width: 100%;
    align-self: center;
    z-index: 2;
    display: flex;
    background: #1B1534BF;   
    margin-top:0px;
}

.logo {
    width: 36%;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: rem(64) rem(96);
    margin-right:3em;
    font-size: 1em;
    font-weight: bold;;
}

.separator {
    flex-shrink: 0;
    flex-grow: 0;
    width: 1px;
    background-color: #7B919D;
    margin: rem(64) 0;
}

.form {
    width: 63%;
    padding: rem(124) rem(96);
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:3em;
}

.header1 {
    font-size: rem(44);
    line-height: rem(52);
}

.header2 {
    font-size: rem(24);
    line-height: rem(32);
}

.formControls {
    color: #fff !important;
    margin: rem(64) 0 !important;
    display: flex;
    align-items: center;
}

.inputIcon {
    margin-right:5px;
}


.input :global .ant-form-item {
    margin: 0!important;
}

.input :global .ant-form-item-explain {
    position: absolute;
    bottom: rem(-24);
}

.formFooter {
    align-self: flex-end;
    display: flex;
    align-items: baseline;
    white-space: break-spaces;
}
.se-main-background{ 
    
	background-attachment: scroll;    
    background-repeat: no-repeat,repeat;
    background-size: contain;
	display: block;
	height: 100%;
	width:100%;
	margin:0
}


