.ds_card_title {
    font-size: 17px;
    color:#51227e;
}

.image-container {
   position: relative;
    display: inline-block; 
}

.round-span {
    display: block;
    position: absolute;
    width: 115px;
    height: 115px;
    background-color: rgb(255 255 255);
    border: 5px solid rgb(72 31 107);
    border-radius: 50%;
    text-align: center;
    line-height: 120px;
    color: #20143b;
    font-weight: bold;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    border-width: 2px;
    transition: all 0.3s ease;
}

.mask_parent:hover .round-span {
    width: 130px;
    height: 130px;
    font-size: 30px;
    line-height: 130px;
}

.mask_parent:hover .round-span-2 {
    width: 140px;
    height: 140px;
    font-size: 30px;
    line-height: 145px;
}

.round-span-2 {
    display: block;
    position: absolute;
    width: 120px;
    height: 120px;
    background-color: rgb(255 255 255);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
}

.ds_badge {
    position: absolute;
    z-index: 1;
    top: 1%;
    right: 5%;
}

.badge_siemens {
    background-color: #ffffff;
    color: #491d72;
    transition: all 0.3s ease;
}

.badge_siemens:hover {
    font-size: 14px;
}

.text_sections_label {
    color: #1b1533;
    font-weight: bolder;
    font-size: 16px;
}

.required_star {
    color: red;
}

.form-switch .form-check-input:checked[type="checkbox"]:after {
    background-color: #1b1533;
}

.form-check-input[type="checkbox"]:checked {
    background-color: #8a7aca;
}

.form-check-input[type="checkbox"]:checked:focus {
    background-color: #8a7aca;
}

.text-siemens {
    color: #1b1533;
}
.card-body {
    flex: 1 1 auto;
    padding: 1.5rem;
}
.circle-btn {  
    position: absolute;  
    /* Adjust the bottom and right values to place the button at the desired location */  
    bottom: 0;  
    right: 0;  
    /* Add your desired button styles neha */  
    background-color: #007bff;  
    color: #fff;  
    border-radius: 5px;  
    border: none;  
    padding: 5px 10px;  
    cursor: pointer;  
} 